var $mq = 'pc';
function switchByWidth(){
  if (window.matchMedia('(max-width: 768px)').matches) {
    $mq = 'sp';
  } else if (window.matchMedia('(max-width:1123px)').matches) {
    $mq = 'tb';
  } else {
    $mq = 'pc';
  }
  console.log($mq);
  
  return $mq;
}

// ロードとリサイズ時 幅の情報を更新
$(window).on('load resize', switchByWidth);

// スマホメニュー開閉
$(function(){
  $mq = switchByWidth();
  if ($mq == 'sp' || $mq == 'tb') {
    $('.js-sp-menu-btn').on("click", function(){
      $('.js-menu').slideToggle();
      $(this).toggleClass('active');
    });
  }
});
// 閉じる
$(function(){
  $mq = switchByWidth();
  if ($mq == 'sp' || $mq == 'tb') {
    $('.js-menu a').on("click", function(){
      $('.js-menu').slideToggle();
    });
  }
});



// タブ切り替え
$(function(){
  $mq = switchByWidth();
  if ($mq == 'sp') {
    $('[data-panel-name]').hide();
    $('[data-panel-name]' + '.active').show();
  
    $("[data-tab-name]").on("click",function(){
      var $name = $(this).data('tab-name');
      $("[data-tab-name]").removeClass("active");
      $('[data-panel-name]').hide();
      $(this).toggleClass("active");
      $('[data-panel-name="' + $name + '"]').show();
      $('[data-panel-name="' + $name + '"]').toggleClass("active");
    });
  }
});



// スマホメニュー / 背景スクロール制御
$(function(){
  var scrollPosition;
  $(".modalOpner").on("click", function() {
      scrollPosition = $(window).scrollTop();
      $('body').addClass('fixed').css({'top': -scrollPosition});
  });
  $(".modalCloser").on("click", function() {
      $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , scrollPosition );
  });
});


// PC スマホ 画像入れ替え
$(function(){
  var width = $(window).width();
  if( width < 767){
    $(".switch-img").each(function(){
      $(this).attr("src", $(this).attr("src").replace(".jpg","_sp.jpg"));
    })
  }
});


// // SVG画像のカラー変更
// $(function(){
//   deSVG('.svg_img', true);
// });



// PCのみtel:リンクを無効にする
$(function(){
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
    $('a[href^="tel:"]').on('click',function(e) {
      e.preventDefault();
    });
  }
});

// スムーススクロール
// #で始まるアンカーをクリックした場合に処理
$(function(){
  $('a[href^="#"]').on("click",function(){
    //ヘッダの高さ
    var headerHight = 80;
    // スクロールの速度
    var speed = 400; // ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を数値で取得(ヘッダの高さ分位置をずらす)
    var position = target.offset().top - headerHight;
    // スムーススクロール
    $('body, html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});


// スクロール ヘッダ高さ可変
$(function() {
  var change = 150;
  $(window).scroll(function() {
    if ($(window).scrollTop() > change) {
      $('.js-head').addClass('mini');
    } else {
      $('.js-head').removeClass('mini');
    }
  });
});